import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {SERVER_URL} from "../config";

export const profApi = createApi({
    reducerPath: 'professeurApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${SERVER_URL}/users`,
    }),
    tagTypes: ['prof'],
    endpoints: (builder) => ({

        getUser: builder.query<User, { num_table: string }>({
            query: (data: { num_table: string }) => ({
                url: `/${data.num_table}`,
            }),
            // @ts-ignore
            providesTags: (result = [], error, arg) => {
                return [
                    'prof',
                ]
            }
        }),

        updateAvailability: builder.mutation({
            query: (data: { num_table: string, availability: boolean }) => ({
                url: `/${data.num_table}`,
                method: 'PUT',
                params: {
                    available: data.availability
                }
            }),
            invalidatesTags: ['prof'],

        }),


    })
})

export const {
    useLazyGetUserQuery,
    useUpdateAvailabilityMutation

} = profApi