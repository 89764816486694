export const buttonStyles = {
  components: {
    Button: {

      baseStyle: {
        borderRadius: "45px",
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
