// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Link, SimpleGrid,
    Image,
    Text,
    useColorModeValue, useToast, VStack,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import Logo from "assets/img/logo.jpg";
import React, {useEffect, useState} from "react";
import {FaCheck} from "react-icons/fa";
import {FaX} from "react-icons/fa6";
import {useLazyGetUserQuery, useUpdateAvailabilityMutation} from "../../services/Professeur";

import moment from "moment";
import {Loader} from "../../components/Loader/Loader";
import MiniCard from "../../components/MiniCard/MiniCard";
import SmoothBox from "../../SmoothBox/SmoothBox";


function toastWrapper(toast: any, id: string, title: string, description: string, status: string) {
    if (!toast.isActive(id)) {
        toast({
            id,
            position: "top",
            duration: 5000,
            title,
            description,
            status,
            isClosable: true,
        });
    }
}

function SignUp() {

    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
    const iconSize = "50px";
    const toast = useToast()
    const isActive = false;

    // state
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [formCompleted, setFormCompleted] = useState<boolean>(false);
    const [numTable, setNumTable] = useState<string>('');
    const [user, setUser] = useState<User | null>(null);
    const [birthday, setBirthday] = useState<string>('');
    const [available, setAvailable] = useState<boolean>(false);

    const birthdayRef = React.useRef(null);
    const availableRef = React.useRef(null);

    // query
    const [
        getUser,
        {data, isLoading, error}
    ] = useLazyGetUserQuery();
    const [updateAvailability, {isLoading: isLoadingUpdate,}] = useUpdateAvailabilityMutation()
    const [endMessage, setEndMessage] = useState<string>('')
    const handleUserIdentifcation = async () => {
        if (numTable === '') {
            toastWrapper(toast,
                "blank-input",
                "Erreur",
                "Veuillez saisir un numéro de table",
                "error"
            )
            return;
        }
        try {
            const {status, error, data} = await getUser({num_table: numTable});
            // @ts-ignore
            if (error && error.status === 404) {
                toastWrapper(toast,
                    "id-not-found",
                    "Erreur",
                    "Désolé, ce numéro de table ne figure pas dans la base de compétences.",
                    "error"
                )

                setUser(null)

            } else {
                const user = data as User;
                if (user.availability == null) {
                    setUser(user)
                    // @ts-ignore
                    birthdayRef?.current.scrollIntoView({behavior: 'smooth'})
                } else {

                    toastWrapper(toast,
                        "id-not-found",
                        `Encore merci ${user.Prenoms}`,
                        "Ta disponibilité a bien été prise en compte",
                        "warning")
                    setUser(null)

                }

            }

        } catch (error: any) {

            toastWrapper(
                toast,
                "error-not-known",
                "Erreur",
                "Une erreur s'est produite, veuillez réesayer.",
                "error"
            )

        }


    }
    const handleUserVerification = async () => {
        if (moment(birthday).format('DD/MM/YYYY') == user?.DateNais) {
            setIsAuth(true)
            // @ts-ignore
        } else {

            toastWrapper(
                toast,
                "incorrect-birthday",
                "Erreur",
                "La date de naissance est incorrecte",
                "error")

        }

    }

    const handleSetAvailable = async () => {
        try {
            // @ts-ignore
            const response = await updateAvailability({num_table: user?.NumTable, availability: available})
            console.log(response)

            if (available) {
                setEndMessage("Nos services vous contacterons très bientôt ")
            } else {
                setEndMessage("Merci de votre participation.")
            }
            setFormCompleted(true)
        } catch (error: any) {
            toastWrapper(
                toast,
                "error-not-known",
                "Erreur",
                "Une erreur s'est produite, veuillez réesayer.",
                "error"
            )
        }
    }


    const formContent = () => {
        if (!isAuth) {
            return <>
                <FormLabel fontSize='sm' fontWeight='normal'>
                    Veuillez saisir votre numéro de table
                </FormLabel>
                <Input
                    fontSize='sm'
                    borderRadius='15px'
                    type='text'
                    placeholder='Numéro de table'
                    mb='24px'
                    w={"100%"}
                    disabled={user != null}
                    value={numTable}
                    onChange={(e) => setNumTable(e.target.value)}
                    size='lg'
                />
                {user && (
                    <><FormLabel fontSize='sm' fontWeight='bold' ref={birthdayRef}>
                        Veuillez nous confirmer votre date de naissance
                    </FormLabel>
                        <Input
                            fontSize='sm'
                            type={'date'}
                            borderRadius='15px'
                            placeholder='Code OTP'
                            mb='24px'
                            size='lg'
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                        /></>)}

                {isLoading ? <Loader/> :
                    <Button
                        type='submit'
                        bg='teal.300'
                        color='white'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        _hover={{
                            bg: "teal.200",
                        }}
                        onClick={user ? handleUserVerification : handleUserIdentifcation}
                        _active={{
                            bg: "teal.400",
                        }}>
                        {!user ? 'Rechercher' : 'Identifier'}
                    </Button>}
            </>
        } else {
            return <>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Etes-vous disponible pour servir dans une école primaire publique à compter de la rentrée scolaire
                    2024-2025 ?
                </FormLabel>
                <HStack spacing='15px' justify='center' mb='22px' ref={availableRef}>
                    <VStack>
                        <Text>
                            <b>Oui</b>
                        </Text>
                        <Flex
                            justify='center'
                            align='center'
                            w={iconSize}
                            h={iconSize}
                            borderRadius='15px'
                            cursor='pointer'
                            bg={available ? 'green.500' : 'green.100'}
                            transition='all .25s ease'
                            onClick={() => !formCompleted && setAvailable(true)}
                            _hover={{filter: "brightness(120%)", bg: bgIcons}}>

                            <Icon
                                color={"white"}
                                as={FaCheck}
                                w='30px'
                                h='30px'
                                _hover={{filter: "brightness(120%)"}}
                            />
                        </Flex>
                    </VStack>
                    <VStack>
                        <Text>
                            <b>Non</b>
                        </Text>
                        <Flex
                            justify='center'
                            align='center'
                            w={iconSize}
                            h={iconSize}
                            borderRadius='15px'
                            border='1px solid lightgray'
                            cursor='pointer'
                            bg={available ? 'red.100' : 'red.500'}
                            onClick={() => !formCompleted && setAvailable(false)}

                            _hover={{filter: "darken(120%)", bg: bgIcons}}>
                            <Icon
                                as={FaX}
                                w='30px'
                                h='30px'
                                color={"white"}
                                _hover={{filter: "brightness(120%)"}}
                            />
                        </Flex>
                    </VStack>
                </HStack>
                {endMessage &&
                    <SmoothBox bg={"green.200"}
                               w={"100%"}
                               textAlign={"center"}
                               mb={"20px"}
                    >
                        <Text fontSize={"sm"}>{endMessage}</Text>
                    </SmoothBox>}

                <Button
                    type='submit'
                    bg='teal.300'
                    fontSize='10px'
                    color='white'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    disabled={formCompleted}
                    onClick={handleSetAvailable}
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}>
                    {formCompleted ? 'Déja rempli' : 'Valider votre réponse'}
                </Button>

            </>
        }
    }
    return (
        <Flex

            direction='column'
            alignSelf='center'
            justifySelf='center'
            overflow='hidden'>
            <Box

                position='absolute'
                minH={{base: "70vh", md: "50vh"}}
                w={{md: "calc(100vw - 50px)"}}
                borderRadius={{md: "15px"}}
                left='0'
                right='0'
                bgRepeat='no-repeat'
                overflow='hidden'
                zIndex='-1'
                top='0'


                bgImage={isActive ? `url(${BgSignUp})` : ''}
                bgSize='cover'
                mx={{md: "auto"}}
                mt={{md: "14px"}}></Box>
            {!isAuth && <Flex
                direction='column'
                textAlign='center'
                justifyContent='center'
                align='center'
                mt='6.5rem'
                mb='30px'>
                {isActive &&
                <Flex
                    borderRadius='15px'
                    p='40px'
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
                    direction={"column"} w={{base: "90%", sm: "80%", lg: "40%", xl: "30%"}} alignItems={"center"}
                    bg={'white'} color={"black"}>
                    <Text fontSize='3xl' color='black' fontWeight='bold'>
                        Bonjour M./Mme
                    </Text>
                    <Text
                        fontSize='md'
                        color='black'
                        fontWeight='normal'
                        textAlign={"justify"}
                        mt='10px'
                        mb='10px'
                    >
                        Si vous êtes déclaré(e) admissible au test de recrutement des Aspirants au Métier d’Enseignant
                        (AME)
                        , session d’octobre 2022, et que vous n’êtes pas encore déployé(e), veillez remplir le
                        formulaire
                        ci-après.
                    </Text>
                </Flex>}

            </Flex>}
            {isAuth &&
                <Flex alignItems='center' direction={"column"} justifyContent='center' mb='30px' mt='6.5rem'>
                    <SimpleGrid columns={{sm: 1, md: 2, xl: 2}} w={{base: "90%", sm: "80%", lg: "40%", xl: "30%"}}
                                spacing='10px'>

                        {Object.entries(user || {})
                            .filter(([key, value]) => ["Nom",
                                "Nom", "Prenoms", "Sexe", "Telephone"].includes(key))
                            .map(([key, value]) => {
                                return <MiniCard fieldName={key} value={value}/>
                            })}
                    </SimpleGrid>
                </Flex>}
            <Flex alignItems='center' direction={"column"} justifyContent='center' mb='60px' mt='20px'>

                <Flex
                    direction='column'
                    w={{base: "90%", sm: "80%", lg: "40%", xl: "30%"}}
                    background='transparent'
                    borderRadius='15px'
                    p='40px'
                    mx={{base: "100px"}}
                    bg={bgColor}
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>

                    <Flex direction={"column"} alignItems={"center"}>
                        <Image src={Logo} alt='Dan Abramov' w={"70%"}/>
                        {isActive ? formContent() : <div>Formulaire fermé</div>}
                    </Flex>


                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignUp;
