// Chakra imports
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import IconBox from "../Icons/IconBox";
import React from "react";

const MiniCard = ({ fieldName, value }: {
  fieldName: string;
    value: string;
}) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card minH='83px'>
      <CardBody>
        <Flex flexDirection='row' align='center' justify='center' w='100%'>
          <Stat me='auto'>
            <StatLabel
              fontSize='sm'
              color='gray.400'
              fontWeight='bold'
              pb='.1rem'>
              {fieldName}
            </StatLabel>
            <Flex>
              <StatNumber fontSize='lg' color={textColor}>
                {value}
              </StatNumber>

            </Flex>
          </Stat>

        </Flex>
      </CardBody>
    </Card>
  );
};

export default MiniCard;