export const inputStyles = {
  components: {
    Input: {

      baseStyle: {
        borderRadius: "100px",
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
