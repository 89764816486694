// chakra imports
import {Box, ChakraProvider} from '@chakra-ui/react';
import Footer from '../components/Footer/Footer';
// core components
import React from 'react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from '../theme/theme';
import SignUp from "../views/Auth/SignUp";
import {SpeedInsights} from "@vercel/speed-insights/react"


export default function Pages(props: any) {
    // ref for the wrapper div
    const wrapper = React.createRef();

    const navRef = React.useRef();

    // @ts-ignore
    return (
        <>
            {/*// @ts-ignore */}
            <ChakraProvider theme={theme} w='100%'>
                {/*// @ts-ignore */}
                <Box ref={navRef} w='100%'>

                    <Box w='100%'>
                        {/*// @ts-ignore */}
                        <Box ref={wrapper} w='100%'>
                            <SignUp/>
                        </Box>
                    </Box>

                </Box>
            </ChakraProvider><SpeedInsights/></>
    );
}
