import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AuthLayout from "./layouts/Auth"
import {configureStore} from "@reduxjs/toolkit";
import {profApi} from "./services/Professeur";
import {Provider} from "react-redux";
import LogRocket from 'logrocket';

LogRocket.init('ministere/formulaire-ministere');

const store = configureStore({
    reducer: {
        [profApi.reducerPath]: profApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(profApi.middleware)
})
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>

            <Routes>
                <Route path={`/`} element={<AuthLayout/>}/>

            </Routes>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
